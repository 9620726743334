import React, { memo, useEffect, useRef, useState } from 'react'
import {
  MDXEditor,
  toolbarPlugin,
  UndoRedo,
  listsPlugin,
  linkPlugin,
  diffSourcePlugin,
  CreateLink,
  BoldItalicUnderlineToggles,
  DiffSourceToggleWrapper,
  ListsToggle,
  BlockTypeSelect,
  markdownShortcutPlugin,
  linkDialogPlugin,
  MDXEditorMethods,
  headingsPlugin,
  thematicBreakPlugin,
  imagePlugin,
  tablePlugin,
} from '@convaise/mdxeditor-react'
import '@convaise/mdxeditor-react/style.css'
import showdown from 'showdown'

// @material-ui components
import { makeStyles } from 'tss-react/mui'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { cleanupMarkdown } from 'utils/stringUtils'

// Type
const markdownConverter = new showdown.Converter()

const useStyles = makeStyles()((theme) => ({
  mdxEditorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
  },
  editorContainer: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '300px',
    flexGrow: 1, // grow to fill available space of parent
    border: `1px solid ${theme.palette.grey[500]}`,
    height: '100%',
    width: '100%',
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
  },
  editorTextfield: {
    // Note: We need this complex structure because we have no other way to style the individual elements of the markdown editor package
    // we do this to make the p element span the whole space to allow a click anywhere to access the input.
    // if we do not do this and the input is empty, the user has to specifically click in the first and only row of the element to activate the text input
    // height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    '& #mdx-editor-link-dialog-save-button': {
      background: theme.palette.success.main,
      width: '120px',
      textAlign: 'center',
      cursor: 'pointer',
    },
    '& #mdx-editor-link-dialog-cancel-button': {
      width: '120px',
      textAlign: 'center',
      cursor: 'pointer',
    },
    '& > div:nth-of-type(1)': {
      // toolbar
      borderRadius: `${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px 0 0`,
      overflowX: 'unset',
    },
    '& > div:nth-of-type(2)': {
      flexGrow: 1,
      '& > div:nth-of-type(1)': {
        height: '100%',
        '& > div:nth-of-type(1)': {
          height: '100%',
          '& > div:nth-of-type(1)': {
            height: '100%',
          },
        },
      },
    },
  },
  hideSecondEditorDiv: {
    // height: 0,
    '& [data-radix-popper-content-wrapper]': {
      // this styles the popup (e.g. link popup)
      position: 'absolute',
      top: '1rem',
      left: '1rem',
      zIndex: '10000 !important',
      transformOrigin: '0px 0px',
      '--radix-popper-transform-origin': '0px 0px',
      // '& > div:nth-of-type(1)': {
      //   // '& > form:nth-of-type(1)': {
      //   //   '& > div:nth-of-type(2)': {
      //   //     display: 'none',
      //   //   },
      //   // },
      //   display: 'none !important',
      // },
    },
  },
  editorContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > p': {
      margin: 0,
      flexGrow: 1,
    },
  },
}))

type AdditionalButtonProps = {
  onClick: (() => void) | (() => Promise<void>)
  icon: string // remix icon e.g. ri-list-unordered
  ariaLabel: string
  tooltip: string
}[]

// const dictionary = {
//   addColumnAfter: 'Spalte nachher einfügen',
//   addColumnBefore: 'Spalte vorher einfügen',
//   addRowAfter: 'Zeile nachher einfügen',
//   addRowBefore: 'Zeile vorher einfügen',
//   alignCenter: 'Align center',
//   alignLeft: 'Align left',
//   alignRight: 'Align right',
//   bulletList: 'Auflistung',
//   checkboxList: 'Todo Liste',
//   codeBlock: 'Code block',
//   codeCopied: 'Kopiert',
//   codeInline: 'Code',
//   createLink: 'Link erstellen',
//   createLinkError: 'Sorry, an error occurred creating the link',
//   createNewDoc: 'Create a new doc',
//   deleteColumn: 'Spalte löschen',
//   deleteRow: 'Zeile löschen',
//   deleteTable: 'Tebelle löschen',
//   deleteImage: 'Delete image',
//   downloadImage: 'Download image',
//   replaceImage: 'Replace image',
//   alignImageLeft: 'Float left half width',
//   alignImageRight: 'Float right half width',
//   alignImageDefault: 'Center large',
//   em: 'Kursiv',
//   embedInvalidLink: 'Sorry, that link won’t work for this embed type',
//   findOrCreateDoc: 'Find or create a doc…',
//   h1: 'Große Überschrift',
//   h2: 'Mittlere Überschrift',
//   h3: 'Kleine Überschrift',
//   heading: 'Überschriften',
//   hr: 'Divider',
//   image: 'Bild',
//   imageUploadError: 'Sorry, an error occurred uploading the image',
//   imageCaptionPlaceholder: 'Schreiben Sie einen Titel',
//   info: 'Info',
//   infoNotice: 'Info notice',
//   link: 'Link',
//   linkCopied: 'Link kopiert',
//   mark: 'Highlight',
//   newLineEmpty: "'/' tippen zum einfügen…",
//   newLineWithSlash: 'Tippen Sie weiter, um zu filtern...',
//   noResults: 'Keine Ergebnisse',
//   openLink: 'Link öffnen',
//   orderedList: 'Geordnete Liste',
//   pageBreak: 'Seitenumbruch',
//   pasteLink: 'Link einfügen...',
//   pasteLinkWithTitle: (title) => `Paste a ${title} link…`,
//   placeholder: 'Platzhalter',
//   quote: 'Zitat',
//   removeLink: 'Link entfernen',
//   searchOrPasteLink: 'Link einfügen...',
//   strikethrough: 'Durchgestrichen',
//   strong: 'Bold',
//   subheading: 'Untertitel',
//   table: 'Tabelle',
//   tip: 'Tip',
//   tipNotice: 'Tip notice',
//   warning: 'Warning',
//   warningNotice: 'Warning notice',
// }

type MarkdownEditorProps = {
  input?: string | undefined | null
  // defaultInput?: string
  type?: 'markdown' | 'html'
  onChangeCallback: (markdown: string) => void
  onBlur?: () => void
  readOnly?: boolean
  placeholder?: string
}

// TODO: img and links
// TODO: vars
// TODO: dynamic popover based on set anchorEl
/**
 *
 */
export default memo(function RMEEditor({
  input,
  // defaultInput,
  type = 'markdown',
  onChangeCallback,
  onBlur,
  readOnly,
  placeholder = 'Bitte eingeben',
}: MarkdownEditorProps): React.ReactElement {
  const { classes } = useStyles()
  const { lockState } = useLockingContext()
  const editorRef = useRef<MDXEditorMethods>(null)
  const [initialValue, setInitialvalue] = useState<string>()
  const [value, setValue] = useState<string>() // this is just to have the current value of the editor in this component for use. The editor does not use this value as it has its own state.
  // Update theme
  // const customTheme = theme
  // customTheme.zIndex = 1300 // zIndex needs to be over 1200 since the popup for the creation processes are at zIndex: 1200

  /**
   * Postprocesses content of markdown editor.
   * Editor makes a few strange things:
   * 1. If trailing space in string, uses "&#x20"
   * 2. If we want to reference variable ${variablename} it escapes the first {
   * 3. Escapes '*'
   * We correct both things here
   * @param content
   */
  function postProcessInput(content: string): string {
    content = content.replaceAll('$\\{', '${')
    content = content.replaceAll('&#x20', ' ')
    content = content.replaceAll('\\*', '*')
    return content
  }

  /**
   * Somehow parsing html to markdown sometimes inserts '<' and '>' into the link part of markdown links.
   * [Google](<https://www.google.com>). We need to remove them to prevent crashes.
   * @param markdown
   */
  function fixHtmlMarkdownLinks(markdown: string): string {
    const regex = /\[([^\]]+)\]\(([^)]+)\)/g
    let match
    while ((match = regex.exec(markdown)) !== null) {
      if (match.length < 3) continue
      const url = match[2]
      const newUrl = url.replaceAll('<', '').replaceAll('>', '')
      markdown = markdown.replaceAll(url, newUrl)
    }

    return markdown
  }

  function cleanUpHTML(html: string): string {
    // cleaning up a few things the parsers would parse the wrong way
    let output = html
    try {
      // remove <p><br><p> lines
      const reg1 = /^<p><br><\/p>$/gm
      output = output.replace(reg1, '')
    } catch (err) {
      console.error(err)
    }
    return output
  }

  function cleanUpFromHTMLParsedMarkdown(markdown: string): string {
    // only function at the moment to clean up linebreaks "<br>" -> "\"
    let output = cleanupMarkdown(markdown)

    output = fixHtmlMarkdownLinks(output)

    return output
  }

  function cleanUpEncodings(markdown: string): string {
    let output = markdown
    if (typeof output === 'string' && output.length > 0) {
      try {
        // case 1: if a convaise data point or an adaptive expression is used in link urls it will get encoded and therefore not usable
        const reg1 = /\$%7B/gm // ${ opening
        const reg2 = /%7D/gm // } closing
        output = output.replace(reg1, '${')
        output = output.replace(reg2, '}')
        const reg3 = /\\\[/gm // so \[ will be replaced with [
        const reg4 = /\\\]/gm // so \] will be replaced with ]
        output = output.replace(reg3, '[')
        output = output.replace(reg4, ']')
        const reg5 = /‘/gm // so ‘ will be replaced with '
        const reg6 = /’/gm // so ’ will be replaced with '
        output = output.replace(reg5, "'")
        output = output.replace(reg6, "'")
        const reg7 = /“/gm // so “ will be replaced with "
        const reg8 = /”/gm // so ” will be replaced with "
        output = output.replace(reg7, '"')
        output = output.replace(reg8, '"')
        const reg9 = /\n\\/gm // some cards have a random "\n\" in their value. This cannot be correctly parsed by the editor. So we simply replace it with "\n"
        output = output.replace(reg9, '\n')
      } catch (err) {
        console.error(err)
      }
    }
    return output
  }

  /**
   * Cleanup some chars added by some weird behaviour of the editor.
   * Last line could be a single '/'; we remove this
   * Last chars could be ' ;', we remove these as well. They happen if the last char is a space, the editor adds the ; here.
   * @param newValue
   * @returns
   */
  function cleanUpCharsAddedByEditor(newValue: string): string {
    const lines = newValue.split(/\r?\n/)
    // check if last line is only a slash and remove it
    if (lines[lines.length - 1].length === 0) {
      lines.pop()
    }
    // remove trailing '/'
    const reg1 = /^\\$/gm
    if (reg1.test(lines[lines.length - 1])) {
      lines.pop()
    }

    // remove trailing ' ;'
    let output = lines.join('\n')
    if (output.substring(output.length - 2) === ' ;') {
      output = output.substring(0, output.length - 1)
    }

    // gets diff between two strings
    function _getDiff(a: string, b: string): { index: number; a: string; b: string }[] {
      const diff: { index: number; a: string; b: string }[] = []
      const len = Math.max(a.length, b.length)

      for (let i = 0; i < len; i++) {
        if (a[i] !== b[i]) {
          diff.push({ index: i, a: a[i] || '', b: b[i] || '' })
        }
      }

      return diff
    }

    // remove ';' added through combination of space and newline
    // the editor somehow adds a semicolon in the markdown output (preview) if there are some combinations with spaces and newlines
    // this removes most of these cases
    // case that does not work: multiple spaces and a random newline somewhere in them still adds the semicolon
    const diff = _getDiff(value ?? '', newValue)
    if (diff.length > 1 && diff[0].b === ';' && diff[1].b === '\n') {
      output = output.substring(0, diff[0].index) + output.substring(diff[1].index)
    }

    return output
  }

  function prepareMarkdownFromHtml(htmlText: string): string {
    const cleanedHtml = cleanUpHTML(htmlText)
    const markdown = markdownConverter.makeMarkdown(cleanedHtml)
    const cleaned = cleanUpFromHTMLParsedMarkdown(markdown)
    return cleaned
  }

  useEffect(function () {
    if (input) {
      setInitialvalue(input)
      setValue(input)
    }
  }, [])

  useEffect(() => {
    // the MDXEditor somehow adds a second div that gets style set through our custom styles (height etc.).
    // this causes the whole Studio page to be scrollable. We are not sure how we can prevent this div, so we simply find it and hide it.
    const targetElement = document.querySelector('div[class$="editorTextfield"]:last-child')
    if (targetElement) {
      targetElement.classList.add(classes.hideSecondEditorDiv)
    }
  }, [classes.hideSecondEditorDiv])

  useEffect(() => {
    if (input !== value && input) {
      if (type === 'markdown') {
        // external change - only required for answers
        editorRef.current?.setMarkdown(input)
      }
    }
  }, [input, type])

  return (
    // this is ugly - for now we need this constellation to properly size input fields etc.
    <div className={classes.mdxEditorWrapper}>
      <div className={classes.editorContainer}>
        <MDXEditor
          ref={editorRef}
          // dictionary={dictionary}
          readOnly={lockState !== 'canEdit'}
          markdown={type === 'markdown' ? cleanUpEncodings(input || '') : prepareMarkdownFromHtml(input || '')} // for diff view
          plugins={[
            thematicBreakPlugin(),
            headingsPlugin(),
            imagePlugin(),
            tablePlugin(),
            listsPlugin(),
            linkPlugin(),
            linkDialogPlugin({}),
            markdownShortcutPlugin(),
            diffSourcePlugin({
              diffMarkdown:
                type === 'markdown'
                  ? cleanUpEncodings(initialValue || '')
                  : cleanUpFromHTMLParsedMarkdown(markdownConverter.makeMarkdown(cleanUpHTML(initialValue || ''))),
              viewMode: 'rich-text',
            }),
            toolbarPlugin({
              toolbarContents: () => (
                <>
                  <BoldItalicUnderlineToggles />
                  {/* <Separator /> */}
                  <BlockTypeSelect />
                  <ListsToggle />
                  <CreateLink />
                  <DiffSourceToggleWrapper>
                    <UndoRedo />
                  </DiffSourceToggleWrapper>
                </>
              ),
            }),
            // diffSourcePlugin(),
          ]}
          // toolbarComponents={toolbarComponents}
          placeholder={placeholder}
          // autoFocus
          // readOnly={readOnly}
          // theme={customTheme}
          className={classes.editorTextfield}
          // contentEditableClassName={classes.editorContent}d
          // disableExtensions={['placeholder', 'highlight', 'emoji', 'checkbox_item', 'checkbox_list', 'container_notice']}
          onChange={(value: string): void => {
            if (readOnly) return
            // const stripRegEx = new RegExp(/^\\\W*$/gm)
            // const savedValue = stripRegEx.test(value) ? value.replace(stripRegEx, '') : value
            // console.log(stripRegEx.test(value), savedValue)
            value = postProcessInput(value)
            if (type === 'markdown') {
              value = cleanUpEncodings(value) // FIXME: workaround
              value = cleanUpCharsAddedByEditor(value) // remove unnecessary / if list etc.
              setValue(value)
              onChangeCallback(value)
            } else if (type === 'html') {
              value = cleanUpCharsAddedByEditor(value)
              setValue(value)
              const reg = /^\\$/gm // to replace \ lines with <br>
              value = value.replace(reg, '<br>')
              const html = markdownConverter.makeHtml(value)
              onChangeCallback(html)
            }
          }}
          // onBlur={onBlur}
        />
      </div>
    </div>
  )
})
