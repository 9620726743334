import { FormControlLabel, FormGroup, Switch, Typography, useTheme } from '@mui/material'
import { Option, SelectDropdown } from 'components/Dropdown'
import { useBotContext } from 'hooks/contexts/bot-context'
import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { BotSettings } from '../../@types/Settings/types'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { Textfield } from 'components/TextInput/Textfield'

type Colors = {
  primaryColor?: string
  primaryColorText?: string
  disabledColor?: string
}

const useStyles = makeStyles()((theme) => ({
  content: {},
  buttons: { display: 'flex' },
  button: { width: '180px', margin: `0 ${theme.spacing(1)}` },
  heading: { margin: `${theme.spacing(2)} 0`, marginBottom: theme.spacing(3) },
  row: { display: 'flex' },
  keyTypography: { margin: 'auto 0' },
  generalContainer: { marginTop: theme.spacing(5) },
  stylesContainerContent: { display: 'flex', flexDirection: 'column' },
  avatarContainer: { marginTop: theme.spacing(2), width: '100%', marginBottom: theme.spacing(2) },
  settingWrapperWithMargin: { marginTop: theme.spacing(2) },
  colorsContainer: {
    maxWidht: '200px',
    maxHeight: '200px',
  },
  avatarUpload: {
    height: '200px',
    width: '200px',
    border: '3px dotted grey',
    display: 'flex',
  },
  avatarImage: {
    width: '100%',
    height: 'fit-content',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  colorPreview: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: theme.spacing(1),
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
}))

const modeOptions = [
  { label: 'Prozess', value: 'process' },
  { label: 'QnA', value: 'qna' },
]

type WebchatSettingsProps = {
  webchatSettings: BotSettings['webchatSettings']
  onWebchatSettingsChange: (newWebchatSettings: BotSettings['webchatSettings']) => void
}

export default function WebchatSettings({
  webchatSettings,
  onWebchatSettingsChange,
}: WebchatSettingsProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { bot } = useBotContext()
  const { lockState } = useLockingContext()

  // Custom webchat settings
  const [useWebsockets, setUseWebsockets] = useState<boolean>(true)
  const [dataprotectionOptIn, setDataprotectionOptIn] = useState<boolean>(false)
  const [allowSecondaryMode, setAllowSecodaryMode] = useState<boolean>(false)
  const [secondaryModeTitle, setSecondaryModeTitle] = useState<string>()
  const [primaryMode, setPrimaryMode] = useState<'process' | 'qna'>()
  const [disableTextInputLengthLimit, setDisableTextInputLengthLimit] = useState<boolean>(false)

  function onUseWebsocketsChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUseWebsockets(event.target.checked)
    onWebchatSettingsChange({ ...webchatSettings, useWebsockets: event.target.checked })
  }

  function onDataprotectionOptInChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDataprotectionOptIn(event.target.checked)
    onWebchatSettingsChange({ ...webchatSettings, dataprotectionOptIn: event.target.checked })
  }

  function onAllowSecondaryModeChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAllowSecodaryMode(event.target.checked)
    onWebchatSettingsChange({ ...webchatSettings, allowSecondaryMode: event.target.checked })
  }

  function onPrimaryModeChange(selectedMode: Option) {
    setPrimaryMode(selectedMode.value as 'process' | 'qna')

    onWebchatSettingsChange({ ...webchatSettings, primaryMode: selectedMode.value as 'process' | 'qna' })
  }

  function onDisableTextInputLengthLimitChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDisableTextInputLengthLimit(event.target.checked)
    onWebchatSettingsChange({ ...webchatSettings, disableTextInputLengthLimit: event.target.checked })
  }

  function handleSecondaryModeTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSecondaryModeTitle(event.target.value)
    onWebchatSettingsChange({
      ...webchatSettings,
      webchatTexts: { ...(webchatSettings?.webchatTexts ?? {}), secondaryConversationModeTitle: event.target.value },
    })
  }

  useEffect(() => {
    if (webchatSettings) {
      if (useWebsockets !== webchatSettings.useWebsockets) setUseWebsockets(webchatSettings.useWebsockets ?? true)
      if (dataprotectionOptIn !== webchatSettings.dataprotectionOptIn)
        setDataprotectionOptIn(!!webchatSettings.dataprotectionOptIn)
      if (allowSecondaryMode !== webchatSettings.allowSecondaryMode)
        setAllowSecodaryMode(!!webchatSettings.allowSecondaryMode)
      if (secondaryModeTitle !== webchatSettings.webchatTexts?.secondaryConversationModeTitle)
        setSecondaryModeTitle(webchatSettings.webchatTexts?.secondaryConversationModeTitle)
      if (primaryMode !== webchatSettings.primaryMode) setPrimaryMode(webchatSettings.primaryMode)
      if (disableTextInputLengthLimit !== webchatSettings.disableTextInputLengthLimit)
        setDisableTextInputLengthLimit(!!webchatSettings.disableTextInputLengthLimit)
    }
  }, [webchatSettings])

  return (
    <div id='webchat-settings' className={classes.generalContainer}>
      <Typography className={classes.heading} variant='h2'>
        Webchat Einstellungen
      </Typography>
      <div className={classes.content}>
        <div className={classes.settingWrapperWithMargin}>
          {bot?.webchatVersion === 'v4' && (
            <div className={classes.settingWrapperWithMargin}>
              <Typography>Primärer Konversationsmodus</Typography>
              <SelectDropdown
                options={modeOptions}
                selected={primaryMode}
                onChange={onPrimaryModeChange}
                isDisabled={lockState !== 'canEdit'}
                width='auto'
              />
            </div>
          )}
          <div className={classes.settingWrapperWithMargin}>
            <FormGroup>
              {bot?.webchatVersion === 'v4' && (
                <FormControlLabel
                  control={<Switch checked={allowSecondaryMode} onChange={onAllowSecondaryModeChange} />}
                  label='Sekundären Konversationmodus aktivieren'
                  disabled={lockState !== 'canEdit'}
                />
              )}
            </FormGroup>
            {bot?.webchatVersion === 'v4' && allowSecondaryMode && (
              <div style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
                <Typography>Titel des sekundären Konversationsmodus</Typography>
                <Textfield
                  placeholder='Titel des sekundären Konversationsmodus'
                  value={secondaryModeTitle}
                  onChange={handleSecondaryModeTitleChange}
                  fullWidth
                  disabled={lockState !== 'canEdit'}
                />
              </div>
            )}
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={useWebsockets} onChange={onUseWebsocketsChange} />}
                label='Websockets verwenden'
                disabled={lockState !== 'canEdit'}
              />
              {bot?.webchatVersion === 'v4' && (
                <FormControlLabel
                  control={<Switch checked={dataprotectionOptIn} onChange={onDataprotectionOptInChange} />}
                  label='Zustimmung zur Datenverarbeitung erforderlich'
                  disabled={lockState !== 'canEdit'}
                />
              )}
              {bot?.webchatVersion === 'v4' &&
                (primaryMode === 'qna' || (primaryMode === 'process' && allowSecondaryMode)) && (
                  <FormControlLabel
                    control={
                      <Switch checked={disableTextInputLengthLimit} onChange={onDisableTextInputLengthLimitChange} />
                    }
                    label='Texteingabe-Limit deaktivieren'
                    disabled={lockState !== 'canEdit'}
                  />
                )}
            </FormGroup>
          </div>
        </div>
      </div>
    </div>
  )
}
